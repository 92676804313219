import React, { Component } from 'react'
import { MapContainer, Marker, Popup, Polyline, WMSTileLayer } from 'react-leaflet'

import LocateControl from './LocateControl';

import L from 'leaflet';

const myCustomColour = '#e01826'

const markerHtmlStyles = `
  background-color: ${myCustomColour};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1rem;
  top: -1rem;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  border: 2px solid #FFFFFF`


export default class Map extends Component {
  render() {

    const polylinePositions1 = [
    
      [
        47.419451927929444,
        7.503016859086837        
      ],
      [
        47.420171928153927,
        7.502156342581001        
      ],
      [
        47.420386350389741,
        7.501484719707038        
      ],
      [
        47.420397491553729,
        7.500831410299869        
      ],
      [
        47.42051009333408,
        7.500500239821572        
      ],
      [
        47.421184600494875,
        7.500600403219278        
      ],
      [
        47.421926668350473,
        7.500501861413223        
      ],
      [
        47.423229022281227,
        7.500494075453425        
      ],
      [
        47.423372865275731,
        7.50061351454585        
      ],
      [
        47.423561832688669,
        7.500441445351759        
      ],
      [
        47.424603210200509,
        7.50402095246673        
      ],
      [
        47.425114840321726,
        7.505850506797259        
      ],
      [
        47.425992687273123,
        7.507365134827685        
      ],
      [
        47.42609433784741,
        7.507336106846327        
      ],
      [
        47.426652459382161,
        7.506501844883367        
      ],
      [
        47.427336317571374,
        7.505972556776808        
      ],
      [
        47.427972897445514,
        7.504750019541586        
      ],
      [
        47.428353621477058,
        7.504264065602764        
      ],
      [
        47.428749339863629,
        7.50430430881387        
      ],
      [
        47.428758147894335,
        7.504635670977118        
      ],
      [
        47.428557044199088,
        7.505579110474339        
      ],
      [
        47.4307608821141,
        7.505084798196604        
      ],
      [
        47.431600171702677,
        7.504837967332497        
      ],
      [
        47.431683001878504,
        7.504688289831925        
      ],
      [
        47.43188555527248,
        7.504357166761356        
      ],
      [
        47.432942491180469,
        7.504126489289614        
      ],
      [
        47.433415159054007,
        7.503265473523178        
      ],
      [
        47.43355045183413,
        7.502569734745826        
      ],
      [
        47.433348120875372,
        7.502503219204169        
      ],
      [
        47.433654549939398,
        7.501335791644243        
      ],
      [
        47.433888985947405,
        7.500222615543191        
      ],
      [
        47.434645401145239,
        7.498460492985374        
      ],
      [
        47.435635523934486,
        7.496923919340733        
      ],
      [
        47.436553499926774,
        7.495745124816398        
      ],
      [
        47.437534766760258,
        7.493837261637903        
      ],
      [
        47.438245080306451,
        7.494301960747515        
      ],
      [
        47.438703921612976,
        7.493998858046959        
      ],
      [
        47.439064266516318,
        7.492749126935516        
      ],
      [
        47.439199473446173,
        7.492102337139781        
      ],
      [
        47.438965933902743,
        7.491423368670885        
      ],
      [
        47.439659647857674,
        7.488746176605291        
      ],
      [
        47.44011889536776,
        7.487436820594368        
      ],
      [
        47.440614063644858,
        7.486228226690779        
      ],
      [
        47.440947335299704,
        7.484995609253175        
      ],
      [
        47.442143320116699,
        7.485569349516484        
      ],
      [
        47.442791041077243,
        7.485196046461591        
      ],
      [
        47.443375733619021,
        7.484997682331571        
      ],
      [
        47.444320227047896,
        7.484700185170617        
      ],
      [
        47.444453394441176,
        7.484558437870018        
      ],
      [
        47.444909636124414,
        7.483930390596341        
      ],
      [
        47.445119294084634,
        7.483678660860855        
      ],
      [
        47.445274017170554,
        7.483612498261815        
      ],
      [
        47.44540266701943,
        7.483521123142744        
      ],
      [
        47.445919774865693,
        7.483654133596414        
      ],
      [
        47.446436857101972,
        7.483853438921813        
      ],
      [
        47.446819002573697,
        7.484118928097572        
      ],
      [
        47.447066249429511,
        7.484351160925415        
      ],
      [
        47.44731352158739,
        7.484517102534928        
      ],
      [
        47.447583161840626,
        7.484981386463637        
      ],
      [
        47.448032602145489,
        7.485644713017886        
      ],
      [
        47.448281469663954,
        7.486311852272506        
      ],
      [
        47.448618810724476,
        7.486153040566387        
      ],
      [
        47.448732936063344,
        7.486398432614561        
      ],
      [
        47.448800407529127,
        7.486358714847092        
      ],
      [
        47.448845371331217,
        7.486374665262686        
      ],
      [
        47.448901154277252,
        7.486325655754293        
      ],
      [
        47.448942563855532,
        7.486234204449481        
      ],
      [
        47.449041559816251,
        7.486081811500207        
      ],
      [
        47.449102760362045,
        7.485979769730563        
      ],
      [
        47.449473524118844,
        7.485456354010979        
      ],
      [
        47.450187299586723,
        7.484055451412016        
      ],
      [
        47.450899364962972,
        7.482369429001651        
      ],
      [
        47.450999573161873,
        7.481345868596018        
      ],
      [
        47.45089202299377,
        7.480285018412134        
      ],
      [
        47.451233570755981,
        7.480921743304072        
      ],
      [
        47.451415712510027,
        7.482127191811085        
      ],
      [
        47.451590755979105,
        7.483042254306        
      ],
      [
        47.451846920794353,
        7.48348003734651        
      ],
      [
        47.452148992638307,
        7.483815761754355        
      ],
      [
        47.452643483658242,
        7.484286903305732        
      ],
      [
        47.452917765850565,
        7.48437995438246        
      ],
      [
        47.453057170842079,
        7.48438670185375        
      ],
      [
        47.452818637697924,
        7.484877122859301        
      ],
      [
        47.45271500513212,
        7.485387545633062        
      ],
      [
        47.452458479639517,
        7.485877943336428        
      ],
      [
        47.452314364101746,
        7.48640158500794        
      ],
      [
        47.451918685381493,
        7.486255378597573        
      ],
      [
        47.451590499045878,
        7.486016415177634        
      ],
      [
        47.451293894356496,
        7.485518918524966        
      ],
      [
        47.450997185578423,
        7.485279989502867        
      ],
      [
        47.450758827725679,
        7.485319563334927        
      ],
      [
        47.450538429026707,
        7.485432079430509        
      ],
      [
        47.450178559147062,
        7.485703586806046        
      ],
      [
        47.449482346357669,
        7.485887286292982        
      ],
      [
        47.449552412078269,
        7.486106124719196        
      ],
      [
        47.449556842279854,
        7.486271869085922        
      ],
      [
        47.449526194926293,
        7.486438908503966        
      ],
      [
        47.449351674920749,
        7.486526265408466        
      ],
      [
        47.4491852591892,
        7.486589762441352        
      ],
      [
        47.449003552452858,
        7.486655897365508        
      ],
      [
        47.448846116076126,
        7.486755201002461        
      ],
      [
        47.448814603331911,
        7.486837379192163        
      ],
      [
        47.448783075320286,
        7.486956682601143        
      ],
      [
        47.448779421107318,
        7.487094573420793        
      ],
      [
        47.4488197493759,
        7.487445974419069        
      ],
      [
        47.448955172038652,
        7.488372906635663        
      ],
      [
        47.449105952283567,
        7.48912483745519        
      ],
      [
        47.449290018289616,
        7.489847633835796        
      ],
      [
        47.449412056492498,
        7.490489494621224        
      ],
      [
        47.449367004364859,
        7.49067508019724        
      ],
      [
        47.449218277976321,
        7.491404190884618        
      ],
      [
        47.449042798760864,
        7.491616165707079        
      ],
      [
        47.448445419643996,
        7.492001417138058        
      ],
      [
        47.448098228882216,
        7.492046155164314        
      ],
      [
        47.447742993955664,
        7.49197951084845        
      ],
      [
        47.447473256403086,
        7.491793623052097        
      ],
      [
        47.447411253073611,
        7.491670256234856        
      ],
      [
        47.447285455528615,
        7.491404959575248        
      ],
      [
        47.447141763687739,
        7.490927508622932        
      ],
      [
        47.446818072387181,
        7.490713733403986        
      ],
      [
        47.446431462534477,
        7.490408417182064        
      ],
      [
        47.446328057118762,
        7.490348655421016        
      ],
      [
        47.44604940383622,
        7.489977155251792        
      ],
      [
        47.445967572947758,
        7.48994260602932        
      ],
      [
        47.445811040520063,
        7.490024659330809        
      ],
      [
        47.445500405063292,
        7.490798645307515        
      ],
      [
        47.445149364956443,
        7.491408181807929        
      ],
      [
        47.445121397616298,
        7.49159907169149        
      ],
      [
        47.4454539800522,
        7.492036918079569        
      ],
      [
        47.445921377422579,
        7.49268040817071        
      ],
      [
        47.446514734094343,
        7.493224598223407        
      ],
      [
        47.446928172821544,
        7.493841539355669        
      ],
      [
        47.446518548265821,
        7.494676405927334        
      ],
      [
        47.446392159702185,
        7.495657400419844        
      ],
      [
        47.445718497211104,
        7.495672600193356        
      ],
      [
        47.44517790412857,
        7.495775443718199        
      ],
      [
        47.445241873862877,
        7.495544819230595        
      ],
      [
        47.445444416111947,
        7.495180431402624        
      ],
      [
        47.445574915897438,
        7.495001581460373        
      ],
      [
        47.4455606148843,
        7.49481462532005        
      ],
      [
        47.445422200626453,
        7.494616934278095        
      ],
      [
        47.445232455026229,
        7.494555750418479        
      ],
      [
        47.444977051492607,
        7.494497150835895        
      ],
      [
        47.444724375682121,
        7.494376241862255        
      ],
      [
        47.444202710746772,
        7.494395591369583        
      ],
      [
        47.443721421333201,
        7.494620477791337        
      ],
      [
        47.442750546993345,
        7.495483865133666        
      ],
      [
        47.442336807131916,
        7.49550994523314        
      ],
      [
        47.442201786855321,
        7.495735179196782        
      ],
      [
        47.441939824691516,
        7.496216144176397        
      ],
      [
        47.441890249927127,
        7.496434837331559        
      ],
      [
        47.441886507155871,
        7.496729146021741        
      ],
      [
        47.44184943216613,
        7.497130802964975        
      ],
      [
        47.441705368215892,
        7.49744882191515        
      ],
      [
        47.441121515411623,
        7.497722609567481        
      ],
      [
        47.440944348229948,
        7.497690598913143        
      ],
      [
        47.441079142409365,
        7.497922745879421        
      ]

    ]

    const polylinePositions2 = [
     
      [
        47.449502103493622,
        7.485961555156255        
      ],
      [
        47.449403187591791,
        7.485915061703755        
      ],
      [
        47.449301545826913,
        7.485937513765654        
      ],
      [
        47.449214287613209,
        7.485977215182974        
      ],
      [
        47.449037054790537,
        7.486101696226533        
      ]

    ]

    if (typeof window !== 'undefined') {

      const icon = L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`
      })

      return (

          <MapContainer center={[47.43600, 7.49543]} zoom={13} style={{zIndex: 0, height: '500px',}} >
            <WMSTileLayer
            url="https://geowms.bl.ch"
            layers="grundkarte_farbig_group,wanderrouten,wanderwege,kant_oeko_objekte,kant_oeko_objekte_label"
            format="image/jpeg"
            transparent="false"
            attribution='&amp;copy <a href="https://www.baselland.ch/politik-und-behorden/direktionen/volkswirtschafts-und-gesundheitsdirektion/amt-fur-geoinformation">Amt für Geoinformation BL</a>'
            />    
            <LocateControl startDirectly/>              
            <Polyline
              pathOptions={{color: '#e01826', weight: 5}}
              positions={polylinePositions1} />    
            <Polyline
              pathOptions={{color: '#e01826', weight: 5}}
              positions={polylinePositions2} />                  
            <Marker
              icon={icon}
              position={[47.440691, 7.497376]}>
              <Popup>
              <a href="/dittingen-laufen/">Start</a>
              </Popup>
            </Marker>    
            <Marker
              icon={icon}
              position={[47.44554, 7.49481]}>
              <Popup>
                <a href="/dittingen-laufen/dittinger-weide">Dittinger Weide</a>
              </Popup>
            </Marker>            
            <Marker
              icon={icon}
              position={[47.44920, 7.49138]}>
              <Popup>
                <a href="/dittingen-laufen/rumenagger">Rumenagger</a>
              </Popup>
            </Marker>       
            <Marker
              icon={icon}
              position={[47.45233, 7.48640]}>
              <Popup>
                <a href="/dittingen-laufen/rain">Rain</a>
              </Popup>
            </Marker>    
            <Marker
              icon={icon}
              position={[47.45132, 7.48157]}>
              <Popup>
                <a href="/dittingen-laufen/brunnenberg">Brunnenberg</a>
              </Popup>
            </Marker>          
            <Marker
              icon={icon}
              position={[47.44028, 7.48713]}>
              <Popup>
                <a href="/dittingen-laufen/auf-hag-kunstwiese">Auf Hag (Kunstwiese)</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.43901, 7.49149]}>
              <Popup>
                <a href="/dittingen-laufen/auf-hag">Auf Hag</a>
              </Popup>
            </Marker>  
            <Marker
              icon={icon}
              position={[47.43249, 7.50422]}>
              <Popup>
                <a href="/dittingen-laufen/kattel">Kattel</a>
              </Popup>
            </Marker>                  
            <Marker
              icon={icon}
              position={[47.42727, 7.50598]}>
              <Popup>
                <a href="/dittingen-laufen/chatel">Chatel</a>
              </Popup>
            </Marker>                                                     
          </MapContainer>

      )
    }
    return null
  }
}