import React, { Component } from 'react'
import { MapContainer, WMSTileLayer, Marker, Popup, Polyline } from 'react-leaflet'

import LocateControl from './LocateControl';

import L from 'leaflet';

const myCustomColour = '#e01826'

const markerHtmlStyles = `
  background-color: ${myCustomColour};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1rem;
  top: -1rem;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  border: 2px solid #FFFFFF`
  
export default class Map extends Component {
  render() {

    const polylinePositions = [
    
      [47.4157074, 7.7171181],
      [47.4158163, 7.7170644],
      [47.4158751, 7.7169348],
      [47.4159568, 7.7166278],
      [47.4158158, 7.7165171],
      [47.4157057, 7.716331],
      [47.4158311, 7.7157658],
      [47.4158703, 7.7151923],
      [47.415878, 7.7149538],
      [47.4157942, 7.7146539],
      [47.415449, 7.7138359],
      [47.4151043, 7.7131451],
      [47.4148853, 7.7125741],
      [47.414869, 7.712264],
      [47.4149842, 7.711451],
      [47.4149714, 7.7111727],
      [47.4150149, 7.7110271],
      [47.415244, 7.7111409],
      [47.4156442, 7.7111668],
      [47.4158835, 7.7111164],
      [47.4156307, 7.7111562],
      [47.4153582, 7.7111614],
      [47.4150122, 7.7110337],
      [47.4149488, 7.711203],
      [47.4145618, 7.7105716],
      [47.4143914, 7.7103389],
      [47.4142391, 7.7100824],
      [47.414078, 7.7097543],
      [47.4138718, 7.7094485],
      [47.4135068, 7.7086185],
      [47.4128918, 7.7081675],
      [47.4126296, 7.707978],
      [47.4125263, 7.7078914],
      [47.4124456, 7.7077916],
      [47.4123471, 7.7076255],
      [47.4122188, 7.7074592],
      [47.4121768, 7.7073557],
      [47.4121384, 7.7072402],
      [47.4120791, 7.7068185],
      [47.4120352, 7.7067309],

      [47.4120129, 7.7066645],
      [47.4117927, 7.7065932],
      [47.4115906, 7.7064729],
      [47.4115282, 7.7062434],
      [47.4115187, 7.7060512],
      [47.4115425, 7.7058791],
      [47.4117054, 7.7054691],
      [47.4119284, 7.7050993],
      [47.4121586, 7.704736],
      [47.4124523, 7.7036974],
      [47.4125516, 7.7031546],
      [47.4126967, 7.7026584],
      [47.4127522, 7.70235],
      [47.4127853, 7.7020467],
      [47.4127015, 7.7021006],
      [47.4126475, 7.7021401],
      [47.4125754, 7.7021994],
      [47.4124988, 7.702252],
      [47.4124268, 7.7022848],
      [47.4123457, 7.7023308],
      [47.4122962, 7.7023636],
      [47.4122422, 7.7023899],
      [47.4121971, 7.7024427],
      [47.4121475, 7.7024755],
      [47.4121025, 7.7025018],
      [47.412071, 7.7025215],
      [47.4120304, 7.7025545],
      [47.4119854, 7.7025807],
      [47.4119359, 7.702607],
      [47.4118908, 7.7026399],
      [47.4118458, 7.7026595],
      [47.4117917, 7.7027189],
      [47.4117376, 7.702765],
      [47.4116791, 7.7028045],
      [47.411652, 7.7028308],
      [47.4116115, 7.7028439],
      [47.4115845, 7.7028901],
      [47.4115529, 7.7029297],
      [47.4115168, 7.7029627],
      [47.4114538, 7.7030021],
      [47.4114042, 7.7030482],
      [47.4113546, 7.7030877],
      [47.411314, 7.7031538],
      [47.4112734, 7.7032066],
      [47.4112238, 7.7032593],
      [47.4111787, 7.7033187],
      [47.4111381, 7.7033782],
      [47.4110931, 7.7034177],
      [47.4110525, 7.7034572],
      [47.4110254, 7.7035101],
      [47.4110073, 7.7035696],
      [47.4109757, 7.7036092],
      [47.4109487, 7.7036356],
      [47.4109306, 7.7036686],
      [47.4108945, 7.7037281],
      [47.4108629, 7.7037809],
      [47.4108403, 7.7038338],
      [47.4108222, 7.7038867],
      [47.4107906, 7.7039329],
      [47.410768, 7.7039858],
      [47.4107409, 7.7040453],
      [47.4107137, 7.7041048],
      [47.4107181, 7.7041512],
      [47.4107181, 7.7041843],
      [47.4107, 7.7042041],
      [47.410691, 7.7042107],
      [47.4106865, 7.704204],
      [47.4106954, 7.7042372],
      [47.4106819, 7.704257],
      [47.4106594, 7.7042635],
      [47.4106324, 7.70427],
      [47.4106054, 7.7042831],
      [47.4106054, 7.7042831],
      [47.4104975, 7.7042826],
      [47.4104254, 7.7043153],
      [47.4103398, 7.7043944],
      [47.4102136, 7.7045395],
      [47.4101774, 7.7046387],
      [47.4098409, 7.7042714],
      [47.4096704, 7.7040903],
      [47.4094122, 7.7037552],
      [47.4093094, 7.7034804],
      [47.4091598, 7.7032041],
      [47.4090389, 7.7029901],
      [47.4090079, 7.7035425],
      [47.4089438, 7.7040589],
      [47.4089477, 7.7043067],
      [47.408984, 7.7045467],
      [47.4090311, 7.7052054],
      [47.4090303, 7.7055512],
      [47.4089938, 7.7057908],
      [47.4088447, 7.7060617],
      [47.4087033, 7.7061577],
      [47.4085387, 7.7061728],
      [47.4081143, 7.7061084],
      [47.4080306, 7.7061331],
      [47.407954, 7.7061831],
      [47.4078413, 7.7063256],
      [47.4075911, 7.7067576],
      [47.4073449, 7.7070717],
      [47.4072123, 7.7072432],
      [47.4071038, 7.7074507],
      [47.4070268, 7.7076861],
      [47.4069786, 7.7079191],
      [47.4069389, 7.7087681],
      [47.4069307, 7.7091761],
      [47.4068982, 7.7096145],
      [47.4068884, 7.7099722],
      [47.4068837, 7.7100622],
      [47.4069172, 7.7103419],
      [47.4069303, 7.7104957],
      [47.4069228, 7.710652],
      [47.4068261, 7.7108343],
      [47.4066927, 7.7109622],
      [47.4064027, 7.7111091],
      [47.4063752, 7.7113275],
      [47.4063429, 7.7117155],
      [47.4062316, 7.7119733],
      [47.4061262, 7.7120655],
      [47.4060197, 7.7121975],
      [47.4058415, 7.7126271],
      [47.4053386, 7.7130511],
      [47.4052255, 7.7133553],
      [47.4054047, 7.7136477],
      [47.4053487, 7.7137507],
      [47.4050733, 7.7141732],
      [47.4049115, 7.7141326],
      [47.4046514, 7.7138531],
      [47.4041843, 7.7135725],
      [47.4040945, 7.7135455],
      [47.4038022, 7.7135374],
      [47.4034614, 7.7138708],
      [47.4030672, 7.7143351],
      [47.402816, 7.7148412],
      [47.4027051, 7.7153136],
      [47.4025441, 7.7157009],
      [47.4022591, 7.7164267],
      [47.4020916, 7.7168908],
      [47.4020368, 7.7172071],
      [47.4019868, 7.7174453],
      [47.4018878, 7.7174528],
      [47.4018877, 7.717519],
      [47.4018968, 7.7174515],
      [47.4017754, 7.7174575],
      [47.401752, 7.71745079],
      
      [47.4016855, 7.7174371],
      [47.4012808, 7.717435],
      [47.4010645, 7.7176127],
      [47.4006058, 7.7175905],
      [47.4002681, 7.7177874],
      [47.3997264, 7.718639],
      [47.3995638, 7.7189163],
      [47.3993496, 7.7182396],
      [47.3987951, 7.7187732],
      [47.3983623, 7.7192279],
      [47.3978216, 7.7196424],
      [47.3974152, 7.7203357],
      [47.3972284, 7.7195002],
      [47.3974183, 7.719064],
      [47.3974878, 7.7182299],
      [47.3974217, 7.7176732],
      [47.3973015, 7.7171361],
      [47.3972751, 7.7168975],
      [47.3968446, 7.7163985],
      [47.3966029, 7.7159204],
      [47.3965089, 7.7157411],
      [47.3963468, 7.7158197],
      [47.3959047, 7.714526],
      [47.39553, 7.7132127],
      [47.3953464, 7.7109864],
      [47.3956296, 7.7110475],
      [47.3956974, 7.7109088],
      [47.396033, 7.7116059],
      [47.3964637, 7.7120651],
      [47.3970168, 7.712048],
      [47.397447, 7.7127258],
      [47.3977427, 7.7131843],
      [47.3980904, 7.7144776],
      [47.3985607, 7.715255],
      [47.3987895, 7.7154946],
      [47.3995443, 7.7157966],
      [47.4000022, 7.7161368],
      [47.40046, 7.7164968],
      [47.4009576, 7.7171949],
      [47.4012537, 7.7174746]
      
    ]

    if (typeof window !== 'undefined') {

      const icon = L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`
      });   

      return (

          <MapContainer center={[47.40573, 7.71314]} zoom={14} style={{zIndex: 0, height: '500px',}} >
            <WMSTileLayer
            url="https://geowms.bl.ch"
            layers="grundkarte_farbig_group,wanderrouten,wanderwege,kant_oeko_objekte,kant_oeko_objekte_label"
            format="image/jpeg"
            transparent="false"
            attribution='&amp;copy <a href="https://www.baselland.ch/politik-und-behorden/direktionen/volkswirtschafts-und-gesundheitsdirektion/amt-fur-geoinformation">Amt für Geoinformation BL</a>'
            />       
            <LocateControl startDirectly/>               
            <Polyline
              pathOptions={{color: '#e01826', weight: 5}}
              positions={polylinePositions} />                      
            <Marker
              icon={icon}
              position={[47.41597, 7.71664]}>
              <Popup>
              <a href="/arboldswil-titterten/">Start</a>
              </Popup>
            </Marker>    
            <Marker
              icon={icon}
              position={[47.41495, 7.71120]}>
              <Popup>
                <a href="/arboldswil-titterten/ob-dem-dorf">Ob dem Dorf</a>
              </Popup>
            </Marker>            
            <Marker
              icon={icon}
              position={[47.41354, 7.70871]}>
              <Popup>
                <a href="/arboldswil-titterten/hinterchastelen">Hinterchastelen</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.41221, 7.70740]}>
              <Popup>
                <a href="/arboldswil-titterten/eselacker">Eselacker</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.41048, 7.70416]}>
              <Popup>
                <a href="/arboldswil-titterten/eichholzweid">Eichholzweid</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.40903, 7.70296]}>
              <Popup>
                <a href="/arboldswil-titterten/hangematt-gmeinrueti">Hangematt und Gmeinrüti</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.40807, 7.70608]}>
              <Popup>
                <a href="/arboldswil-titterten/tschudismatt-hasenhuebeli">Tschudsimatt/Hasenhübeli</a>
              </Popup>
            </Marker>              
            <Marker
              icon={icon}
              position={[47.40796, 7.70620]}>
              <Popup>
                <a href="/arboldswil-titterten/heuschueuerli">Heuschüürli mit Selbstbedienungsbeizli</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.40624595, 7.71197771]}>
              <Popup>
                <a href="/arboldswil-titterten/rosenmatt">Rosenmatt</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.40534281, 7.71378470]}>
              <Popup>
                <a href="/arboldswil-titterten/lohnsberg">Lohnsberg</a>
              </Popup>
            </Marker>              
            <Marker
              icon={icon}
              position={[47.39729, 7.71974]}>
              <Popup>
                <a href="/arboldswil-titterten/eggrueti">Eggrüti</a>
              </Popup>
            </Marker>     
            <Marker
              icon={icon}
              position={[47.39538, 7.71085]}>
              <Popup>
                <a href="/arboldswil-titterten/mattweid">Mattweid</a>
              </Popup>
            </Marker>                              
          </MapContainer>

      )
    }
    return null
  }
}